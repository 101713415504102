import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import * as _ from 'lodash';
import Layout from '../components/Layout';

const TagsPage = ({
    data: {
        allMarkdownRemark: { group },
        site: {
            siteMetadata: { title }
        }
    }
}) => {
    let grouppedByLetter = _.groupBy(group, l => l.fieldValue.substr(0, 1));
    return (
        <Layout>
            <Helmet title={title} />
            <section className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
                <h1>Tags ({group.length})</h1>
                {Object.keys(grouppedByLetter).map(key => (
                    <>
                        <h3>{key.toUpperCase()}</h3>
                        <ul className="inline-list">
                            {grouppedByLetter[key].map(tag => (
                                <li key={tag.fieldValue}>
                                    <Link
                                        to={`/tags/${kebabCase(
                                            tag.fieldValue
                                        )}/`}
                                    >
                                        {tag.fieldValue} ({tag.totalCount})
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </>
                ))}
            </section>
        </Layout>
    );
};

export default TagsPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`;
